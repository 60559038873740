@import '~rsuite/dist/styles/rsuite-default.css';
@font-face {
  font-family: 'Datalegreya-Thin';
  src: url('../../constants/fonts/Datalegreya-Thin.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Datalegreya-Gradient';
  src: url('../../constants/fonts/Datalegreya-Gradient.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Datalegreya-Dot';
  src: url('../../constants/fonts/Datalegreya-Dot.otf');
  font-weight: normal;
  font-style: normal;
}
@media all and (min-width: 200px)   {  h1  { font-size:1.5em;  } }
@media all and (min-width: 300px)   {  h1  { font-size:1.7em;  } }
@media all and (min-width: 400px)   {  h1  { font-size:2.2em;  } }
@media all and (min-width: 600px)   {  h1  { font-size:2.8em;  } }
@media all and (min-width: 1000px) {  h1  { font-size:3.2em;  } }


.Menu {
  float:left; /* add this */
  /*border: 1px solid red;*/
}

.site-layout-background {
  background: #fff !important;
}

.ant-layout .ant-layout-footer {
  background: #fff !important;
}

.ant-layout .ant-layout-header {
  background: #fff !important;
}

.ant-layout {
  background: #fff !important;
}

body {
  -webkit-font-feature-settings: "kern" on, "liga" on, "calt" on !important;
  -moz-font-feature-settings: "kern" on, "liga" on, "calt" on !important;
  -webkit-font-feature-settings: "kern" on, "liga" on, "calt" on !important;
  -ms-font-feature-settings: "kern" on, "liga" on, "calt" on !important;
  font-feature-settings: "kern" on, "liga" on, "calt" on !important;
  font-variant-ligatures: common-ligatures discretionary-ligatures contextual !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  
}


.container {
  text-align: center;
  max-width: 800px;
  position: relative;
  top: 15em;
  left: 50%;
  transform: translateX(-50%);



}
.resize_fit_center {
  width: 100%;
  vertical-align: middle;

}


h1 {
  font-family: 'Datalegreya-Dot' !important;
  font-weight: normal !important;
  font-style: normal !important;
  white-space: nowrap;
  color:#65007a;
}

.toratio-logo {
  float: left;
  width: auto;
  height: 65px;
}

